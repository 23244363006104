import React, { useEffect, useState } from 'react';
import axios from "../../helpers/axios";
import Layout from '../home/layout';
import { Table, Spinner, Alert, Modal, Button, Pagination, Form } from 'react-bootstrap';
import { Typography } from '@mui/material';

const ITEMS_PER_PAGE = 10;

const SentMessages = () => {
    const [userId, setUserId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchUserId = async () => {
            try {
                const response = await axios.get(`/users/profile/${userId}`);
                setUserId(response.data._id);
            } catch (err) {
                setError('Error fetching user information.');
                setLoading(false);
            }
        };

        fetchUserId();
    }, [userId]);

    useEffect(() => {
        const fetchSentMessages = async () => {
            try {
                if (!userId) return;
                const response = await axios.get(`/users/messages/sent/${userId}`);
                const sortedMessages = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setMessages(sortedMessages);
                setTotalPages(Math.ceil(sortedMessages.length / ITEMS_PER_PAGE));
                setLoading(false);
            } catch (err) {
                setError(err.response?.data?.error || 'Error fetching sent messages.');
                setLoading(false);
            }
        };

        fetchSentMessages();
        const interval = setInterval(fetchSentMessages, 10000);
        return () => clearInterval(interval);
    }, [userId]);

    const handleOpenModal = (message) => {
        setSelectedMessage(message);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedMessage(null);
        setShowModal(false);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to first page on search
    };

    const filteredMessages = messages.filter(
        (message) =>
            message.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
            message.recipient.fullName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const paginatedMessages = filteredMessages.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const renderPagination = () => {
        const items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => handlePageChange(number)}
                    style={{
                        margin: '0 5px',
                        borderRadius: '5px',
                        transition: 'background-color 0.3s, color 0.3s',
                        cursor: 'pointer',
                        backgroundColor: number === currentPage ? '#007bff' : 'transparent',
                        color: number === currentPage ? 'white' : '#007bff'
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e9ecef'}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = number === currentPage ? '#007bff' : 'transparent'}
                >
                    {number}
                </Pagination.Item>
            );
        }
        return (
            <Pagination style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                {items}
            </Pagination>
        );
    };

    return (
        <Layout>
            <div className="sent-messages-container">
                {loading ? (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : error ? (
                    <Alert variant="danger">{error}</Alert>
                ) : filteredMessages.length === 0 ? (
                    <Alert variant="info">No messages found.</Alert>
                ) : (
                    <>
                        <Form.Control
                            type="text"
                            placeholder="Search by subject or recipient"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            style={{ marginBottom: '20px', width: '100%' }}
                        />
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th><b>Recipient</b></th>
                                    <th><b>Subject</b></th>
                                    <th><b>Date Sent</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedMessages.map((message) => (
                                    <tr
                                        key={message._id}
                                        onClick={() => handleOpenModal(message)}
                                        style={{ cursor: 'pointer' }}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f8f9fa'}
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
                                    >
                                        <td>{message.recipient.fullName}</td>
                                        <td>{message.subject}</td>
                                        <td>{new Date(message.createdAt).toLocaleString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {renderPagination()}
                    </>
                )}

                <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Sent Message Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedMessage && (
                            <>
                                <Typography variant="h5">{selectedMessage.subject}</Typography>
                                <Typography>To: {selectedMessage.recipient.fullName}</Typography>
                                <Typography>Sent: {new Date(selectedMessage.createdAt).toLocaleString()}</Typography>
                                <hr />
                                <div dangerouslySetInnerHTML={{ __html: selectedMessage.content }} />
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </Layout>
    );
};

export default SentMessages;
