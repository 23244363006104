import React from 'react';
import Header from './header';
import Footer from './footer';

const Layout = ({ children }) => {
  return (
    <div style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
      <Header />
      <div style={{ flexGrow: 1 }}>
        <main style={{ 
          flexGrow: 1, 
          padding: '10px', 
          boxSizing: 'border-box' 
        }}>
          {children}
        </main>
      </div>
      <Footer/>
    </div>
  );
};

export default Layout;
