import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../pages/home/layout';
import {
  Avatar,
  Typography,
  Button,
  Grid,
  TextField,
  CircularProgress,
  Card,
  CardContent,
  Box,
  MenuItem,
  Select
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import WebIcon from '@mui/icons-material/Web';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import HomeIcon from '@mui/icons-material/Home';
import axios from "../../helpers/axios";
import swal from 'sweetalert';
import PersonIcon from '@mui/icons-material/Person';

const defaultProfilePicture = "https://via.placeholder.com/150"; // Placeholder for profile picture

const Profile = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [errors, setErrors] = useState({});
  const [sectorName, setSectorName] = useState("");
  const [role, setRole] = useState("");
  const [sectors, setSectors] = useState([]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`users/profile/${id}`);
        setUserData(response.data);
        if (response.data) {
          if (response.data.sectorId) {
            const sectorResponse = await axios.get(`sectors/${response.data.sectorId}`);
            setSectorName(sectorResponse.data.name);
          }
          setRole(response.data.role || ""); // Set role if available
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        setError(error.message || 'An error occurred while fetching user profile!');
      }
    };

    const fetchSectors = async () => {
      try {
        const response = await axios.get('sectors/all');
        setSectors(response.data);
      } catch (error) {
        console.error('Error fetching sectors:', error);
      }
    };

    fetchUserProfile();
    fetchSectors();
  }, [id]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleProfilePictureChange = (e) => {
    setProfilePicture(e.target.files[0]);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleSectorChange = (e) => {
    const selectedSectorId = e.target.value;
    const selectedSector = sectors.find(sector => sector._id === selectedSectorId);
    setSectorName(selectedSector ? selectedSector.name : '');
    setUserData({ ...userData, sectorId: selectedSectorId });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!userData.fullName || typeof userData.fullName !== 'string' || userData.fullName.trim() === '') {
      newErrors.fullName = 'Full Name is required';
    }
    if (!userData.email || typeof userData.email !== 'string' || userData.email.trim() === '') {
      newErrors.email = 'Email is required';
    }
   
    if (!userData.city || typeof userData.city !== 'string' || userData.city.trim() === '') {
      newErrors.city = 'City is required';
    }
    if (!userData.gender || typeof userData.gender !== 'string' || userData.gender.trim() === '') {
      newErrors.gender = 'Gender is required';
    }
    if (!userData.businessName || typeof userData.businessName !== 'string' || userData.businessName.trim() === '') {
      newErrors.businessName = 'Business Name is required';
    }
    if (!userData.web || typeof userData.web !== 'string' || userData.web.trim() === '') {
      newErrors.web = 'Website is required';
    }
    if (!userData.elevatorPitch || typeof userData.elevatorPitch !== 'string' || userData.elevatorPitch.trim() === '') {
      newErrors.elevatorPitch = 'Elevator Pitch is required';
    }
    if (!userData.sectorId) {
      newErrors.sectorId = 'Sector is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const uploadToCloudinary = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'gxm2yglm'); // Replace with your Cloudinary upload preset

      const response = await fetch('https://api.cloudinary.com/v1_1/sdginnovations/image/upload', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload image to Cloudinary');
      }

      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const handleSaveClick = async () => {
    if (!validateFields()) {
      return;
    }
    setLoading(true);
    try {
      const updatedUserData = { ...userData, role };

      if (profilePicture) {
        const profilePictureUrl = await uploadToCloudinary(profilePicture);
        updatedUserData.profilePicture = profilePictureUrl;
      }

      const response = await axios.put('users/updateProfile', updatedUserData);
      setUserData(response.data);
      setEditMode(false);
      swal('Success', 'Profile Updated Successfully', 'success');
    } catch (error) {
      console.error('Error updating user profile:', error);
      setError(error.message || 'An error occurred while updating user profile');
      swal('Error', 'Error updating profile. Please try again.', 'error');
    }
    setLoading(false);
  };

  if (error) {
    return (
      <Layout>
        <Typography variant="h6">Error: {error}</Typography>
      </Layout>
    );
  }

  return (
    <Layout>
      <Grid container  style={{ marginTop: '150px',  marginBottom: '100px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Avatar
                      src={userData?.profilePicture || defaultProfilePicture}
                      alt="Profile Image"
                      style={{ width: '100px', height: '100px' }}
                    />
                    {editMode && (
                      <Box mt={2}>
                        <input
                          accept="image/*"
                          style={{ display: 'none' }}
                          id="profile-picture-upload"
                          type="file"
                          onChange={handleProfilePictureChange}
                        />
                        <label htmlFor="profile-picture-upload">
                          <Button variant="contained" component="span">
                            Upload 
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        name="fullName"
                        value={userData?.fullName || ''}
                        onChange={handleInputChange}
                        label="Full Name"
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        required
                        error={!!errors.fullName}
                        helperText={errors.fullName}
                      />
                    ) : (
                      <Typography variant="h6">{userData?.fullName}</Typography>
                    )}
                    <Typography variant="body1" style={{ textTransform: 'capitalize' }}>
                      {userData?.role}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card style={{ marginTop: '20px' }}>
              <CardContent>
                <Typography variant="h6">About</Typography>
                {editMode ? (
                  <TextField
                    name="bio"
                    value={userData?.bio || ''}
                    onChange={handleInputChange}
                    label="Bio"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    multiline
                    rows={4}
                  />
                ) : (
                  <Typography variant="body1">{userData?.bio || "No bio available"}</Typography>
                )}
              </CardContent>
            </Card>
            <Card style={{ marginTop: '20px' }}>
              <CardContent>
                <Typography variant="h6">Contact</Typography>
                {editMode ? (
                  <div>
                    <TextField
                      name="email"
                      value={userData?.email || ''}
                      onChange={handleInputChange}
                      label="Email"
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      required
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                    <TextField
                      name="phone"
                      value={userData?.phone || ''}
                      onChange={handleInputChange}
                      label="Phone"
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      required
                      error={!!errors.phone}
                      helperText={errors.phone}
                    />
                    <TextField
                      name="city"
                      value={userData?.city || ''}
                      onChange={handleInputChange}
                      label="City"
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      required
                      error={!!errors.city}
                      helperText={errors.city}
                    />
                    <TextField
                      name="gender"
                      value={userData?.gender || ''}
                      onChange={handleInputChange}
                      label="Gender"
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      required
                      error={!!errors.gender}
                      helperText={errors.gender}
                    />
                  </div>
                ) : (
                  <Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <BusinessIcon style={{ marginRight: '8px' }} />
                      <Typography variant="body1">{userData?.email || "No Email details available"}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <PhoneIcon style={{ marginRight: '8px' }} />
                      <Typography variant="body1">{userData?.phone || "No Phone details available"}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <PersonIcon style={{ marginRight: '8px' }} />
                      <Typography variant="body1">{userData?.gender || "No Gender available"}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <LocationCityIcon style={{ marginRight: '8px' }} />
                      <Typography variant="body1">{userData?.city || "No City available"}</Typography>
                    </Box>
                  </Box>
                )}

                {editMode && (
                  <Box display="flex" alignItems="center" mb={1}>
                    <Select
                      value={role}
                      onChange={handleRoleChange}
                      fullWidth
                      variant="outlined"
                      required
                    >
                      <MenuItem value="innovator">Innovator</MenuItem>
                      <MenuItem value="investor">Investor</MenuItem>
                      <MenuItem value="partner">Partner</MenuItem>
                    </Select>
                  </Box>
                )}
              </CardContent>
            </Card>
            
          </Grid>
          <Grid item xs={12} md={6}>
          <Card>
              <CardContent>
                <Typography variant="h6">Company Details</Typography>
                {editMode ? (
                  <div>
                    <TextField
                      name="businessName"
                      value={userData?.businessName || ''}
                      onChange={handleInputChange}
                      label="Business Name"
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      required
                      error={!!errors.businessName}
                      helperText={errors.businessName}
                    />
                    <TextField
                      name="web"
                      value={userData?.web || ''}
                      onChange={handleInputChange}
                      label="Website"
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      required
                      error={!!errors.web}
                      helperText={errors.web}
                    />
                    <TextField
                      name="elevatorPitch"
                      value={userData?.elevatorPitch || ''}
                      onChange={handleInputChange}
                      label="Elevator Pitch"
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      multiline
                      rows={4}
                      required
                      error={!!errors.elevatorPitch}
                      helperText={errors.elevatorPitch}
                    />
                    <Select
                      name="sectorId"
                      value={userData?.sectorId || ''}
                      onChange={handleSectorChange}
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      required
                      error={!!errors.sectorId}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>Select Sector</MenuItem>
                      {sectors.map(sector => (
                        <MenuItem key={sector._id} value={sector._id}>
                          {sector.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                ) : (
                  <Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <BusinessIcon style={{ marginRight: '8px' }} />
                      <Typography variant="body1">{userData?.businessName || "No business details available"}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <WorkIcon style={{ marginRight: '8px' }} />
                      <Typography variant="body1">{sectorName || "No sector details available"}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <WebIcon style={{ marginRight: '8px' }} />
                      <Typography variant="body1">{userData?.web || "No website details available"}</Typography>
                    </Box>
                    <hr/>
                    <p>ELEVATOR PITCH</p>
                    <hr/>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography variant="body1">{userData?.elevatorPitch || "No elevator pitch available"}</Typography>
                    </Box>
                  </Box>
                )}
              </CardContent>
            </Card>
            {editMode && (
              <Box display="flex" justifyContent="flex-start" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveClick}
                  startIcon={loading ? <CircularProgress size={24} /> : <SaveIcon />}
                  disabled={loading}
                >
                  {loading ? 'Saving...' : 'Save'}
                </Button>
              </Box>
            )}
            {!editMode && (
              <Box display="flex" justifyContent="flex-start" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleEditClick}
                  startIcon={<EditIcon />}
                >
                  Edit
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      
    </Layout>
  );
};

export default Profile;
