import React from 'react';
import { Container,Grid , Typography, Box } from '@mui/material';
import Layout from '../pages/home/layout';
const HelpPage = () => {
  return (
     <Layout>
            <Grid container  style={{ marginTop: '150px',  marginBottom: '100px' }}>
   
      <Box>
      <Typography 
  variant="h6" 
  gutterBottom 
  style={{
    fontFamily: 'Noto Sans, sans-serif',
    fontWeight: 'bold',
    color: '#0468B1', // A nice shade of blue
    letterSpacing: '1px',
    textTransform: 'uppercase',
    marginBottom: '15px',
  }}
>
          Help and Support
        </Typography>
        <hr/>
        <Typography variant="body1" paragraph style={{ textAlign: 'justify' }}>
  Welcome to the help page. Here you can find answers to frequently asked questions and guidance on how to use the application.
</Typography>

        <Typography  gutterBottom style={{ fontWeight: 'bold' }}> 
          Frequently Asked Questions
        </Typography>
        <Typography variant="body1" paragraph style={{ textAlign: 'justify' }}>
          <strong>Q1:</strong> How do I create an innovation?<br />
          <strong>A:</strong> Go to the "My Innovations" page and click on "Add Innovation." Fill out the form and submit it.
        </Typography>
        <Typography variant="body1" paragraph style={{ textAlign: 'justify' }}>
          <strong>Q2:</strong> How do I update my profile?<br />
          <strong>A:</strong> Click on your profile icon, go to the profile page, and edit your details.
        </Typography>
        
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          Contact Support
        </Typography>
        <Typography variant="body1" paragraph style={{ textAlign: 'justify' }}>
          If you need further assistance, please contact our support team at <b>support@ghsdginnovations.org</b>.
        </Typography>
      </Box>
    
    <br/>
    </Grid>
    </Layout>
  );
};

export default HelpPage;
