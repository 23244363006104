import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  IconButton,
} from '@mui/material';
import Layout from './layout';
import { Link } from 'react-router-dom';
import useUserInnovation from '../../hooks/userInnovations';
import AddInnovation from '../../features/innovations/AddInnovation';
import EditInnovationForm from '../../features/innovations/EditInnovation'; // Adjust the import path as necessary
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Helper function to truncate description to 20 words
const truncateDescription = (text, wordLimit) => {
  const words = text.split(' ');
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...';
  }
  return text;
};

const MyInnovationsPage = () => {
  const [listOfInnovations] = useUserInnovation();
  const [openModal, setOpenModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedInnovation, setSelectedInnovation] = useState(null);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleEdit = (innovation) => {
    setSelectedInnovation(innovation);
    setOpenEditDialog(true);
  };

  const handleDelete = (id) => {
    // Implement the logic to handle delete here
    console.log("Delete innovation with ID:", id);
  };

  const handleUpdate = (updatedInnovation) => {
    // Implement the update logic, e.g., sending updatedInnovation to the server
    console.log("Updated innovation:", updatedInnovation);
    setOpenEditDialog(false);
  };

  const filteredInnovations = listOfInnovations.filter(innovation => {
    if (selectedTab === 0) return true; // All innovations
    if (selectedTab === 1) return innovation.approved; // Approved innovations
    if (selectedTab === 2) return !innovation.approved; // Pending innovations
    return true;
  });

  return (
    <Layout>
      <Box
        marginTop='150px'
        marginBottom='100px'
        maxHeight='calc(100vh - 124px)'
      >
        <Typography 
        marginTop='50px'
          variant="h6" 
          gutterBottom 
          style={{
            fontFamily: 'Noto Sans, sans-serif',
            fontWeight: 'bold',
            color: '#0468B1',
            letterSpacing: '1px',
            textTransform: 'uppercase',
            marginBottom: '15px',
          }}
        >
          My Innovations
        </Typography>
        <hr/>
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="All" />
          <Tab label="Approved" />
          <Tab label="Pending" />
        </Tabs>
        <Grid container justifyContent='flex-end'>
          <br/>
          <Button
            onClick={handleOpenModal}
            variant="contained"
            style={{ marginBottom: '20px', backgroundColor: '#0468B1', color: 'white' }}
          >
           <AddIcon />
          </Button>
          <Dialog
            open={openModal}
            onClose={handleCloseModal}
            maxWidth="lg"
            PaperProps={{
              style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxHeight: '650px',
                minHeight: '200px',
                borderRadius: 16,
              },
            }}
          >
            <DialogTitle>Add New</DialogTitle>
            <DialogContent>
              <AddInnovation />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} style={{ color: "red" }}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        {filteredInnovations.length === 0 ? (
         <Box display="flex" justifyContent="center" alignItems="center" height="100%">
         <Typography variant="h6" style={{marginTop:"50px", color: '#0468B1', textAlign: 'center' }}>
           No innovations found.
         </Typography>
       </Box>
        ) : (
          <Box sx={{ overflowX: 'auto', padding:"0px" }}>
            <TableContainer component={Paper} elevation={3}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredInnovations.map((innovation) => (
                    <TableRow
                      key={innovation._id}
                      hover
                      sx={{ cursor: 'pointer' }}
                      component={Link}
                      to={`/innovation/${innovation._id}`}
                    >
                      <TableCell>
                        <Typography sx={{ color: '#0468B1' }}>
                          {innovation.innovationName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="textSecondary">
                          <span style={{ color: innovation.approved ? 'green' : 'orange' }}>
                            {innovation.approved ? 'Approved' : 'Pending'}
                          </span>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="edit"
                          color="primary"
                          onClick={(e) => { e.preventDefault(); handleEdit(innovation); }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={(e) => { e.preventDefault(); handleDelete(innovation._id); }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        <br />
        <br />
        <br />
        <br />
      </Box>

      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit Innovation</DialogTitle>
        <DialogContent>
          {selectedInnovation && (
            <EditInnovationForm
              innovation={selectedInnovation}
              open={openEditDialog}
              onClose={() => setOpenEditDialog(false)}
              onUpdate={handleUpdate}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default MyInnovationsPage;
