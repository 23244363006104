import { useState, useEffect } from "react";
import axios from "axios";
import { api } from "../urlConfig";

function useUserInnovation() {
  const [listOfInnovations, setListOfInnovations] = useState([]);
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    axios
      .get(`${api}innovations/profile/${userId}`, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((response) => {
        const myInnovations = response.data;
        setListOfInnovations(myInnovations);
      });
  }, []);

  return [listOfInnovations];
}

export default useUserInnovation;