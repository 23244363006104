import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Home from "./pages/home/Home";
import "./style/dark.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import NotFound from "./pages/404/404";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import PrivateRoute from "./hooks/PrivateRoute";
import { isUserLoggedIn } from "./actions";
import Reset from "./pages/reset";
import Register from "./features/auth/Register";
import Login from "./features/auth/Login";
import VerifyAccount from "./features/auth/VerifyAccount";
import MyInnovationsPage from "./pages/home/myInnovations";
import InvestorsPage from "./pages/home/investors";
import Dashboard from "./pages/home/dashboard";
import AddInnovation from "./features/innovations/AddInnovation";
import InnovationDetail from "./features/innovations/InnovationDetail";
import EditInnovation from "./features/innovations/EditInnovation";
import Profile from "./features/profile/Profile";
import Main from "./pages/home/main";
import NotificationsAndMessaging from "./pages/home/notification";
import ChatComponent from "./features/innovations/chat";
import InvestorDetailPage from "./pages/home/investorDetailPage";
import Message from "./pages/message";
import ReceivedMessages from "./pages/message/receivedMessages";
import MessageDetail from "./pages/message/inbox";
import FavoritedInnovations from "./pages/home/favourited";
import HelpPage from "./features/helpers";

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 968);
    };

    window.addEventListener("resize", handleResize);

    // Run the function immediately on mount to detect initial screen size
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isUserLoggedIn());
    }
  }, [auth.authenticate, dispatch]);

  return (
    <div className="container">
      <div className={isMobile ? "mobile-menu" : "menu"}>
        
      </div>

      <Routes>
        <Route path="/">
          <Route path="login" element={<Login />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="register" element={<Register />} />
          <Route path="/verify/:userId" element={<VerifyAccount />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Main />} />
            <Route path="/main" element={<Main />} />
            <Route path="/chat" element={<ChatComponent />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/innovations" element={<MyInnovationsPage />} />
            <Route path="/innovation/add" element={<AddInnovation />} />
            <Route path="/innovation/:id" element={<InnovationDetail />} />
            <Route path="/innovation/:id/edit" element={<EditInnovation />} />
            <Route path="/investors" element={<InvestorsPage />} />
            <Route path="/investor/:id" element={<InvestorDetailPage />} />
            <Route path="/myprofile" element={<Profile />} />
            <Route path="/message" element={<Message />} />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/message/:messageId" element={<MessageDetail />} />
            <Route path="/messages/:userId" element={<ReceivedMessages />} />
            <Route path="/notifications" element={<NotificationsAndMessaging />} />
            <Route path="/favourites" element={<FavoritedInnovations />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
