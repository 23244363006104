import React, { useState, useEffect } from 'react';
import axios from "../../helpers/axios";
import Layout from '../home/layout';
import swal from "sweetalert";
import { Form, Button, Container, Row, Col, Alert, Spinner } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles for react-quill

const SendMessage = () => {
    const [recipientId, setRecipientId] = useState('');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [recipientError, setRecipientError] = useState('');
    const [contentError, setContentError] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('/users/getAll'); // Replace with your endpoint to fetch users
            setUsers(response.data);
        } catch (err) {
            console.error('Error fetching users:', err);
        }
    };

    const handleUserSelect = (user) => {
        setSelectedUser(user);
        setRecipientId(user._id); // Assuming user object has an `id` property
        setRecipientError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
  
        // Validate recipient and content
        if (!recipientId) {
            setRecipientError('Please select a recipient.');
            return;
        } else {
            setRecipientError('');
        }
  
        if (!content.trim()) {
            setContentError('Please enter message content.');
            return;
        } else {
            setContentError('');
        }
  
        setLoading(true);
        setError('');
  
        try {
            const response = await axios.post('/users/message', { recipientId, subject, content });
            console.log(response.data); // Optional: Handle success response
            swal("Success", "Message sent successfully!", "success");
            setLoading(false);
            setRecipientId('');
            setSubject('');
            setContent('');
        } catch (err) {
            setLoading(false);
            if (err.response) {
                console.error('Server error:', err.response.data); // Log server response
                setError(err.response.data.message || 'Error sending message.');
            } else {
                console.error('Network error:', err.message); // Log network error
                setError('Network error. Please try again later.');
            }
        }
    };

    return (
        <Layout>
            <Container>
                <Row className="justify-content-md-center mt-5">
                    <Col md={10} lg={8}>
                        <div style={styles.composeContainer}>
                            <h2 style={styles.header}>Compose New Message</h2>
                            <Form onSubmit={handleSubmit} style={styles.form}>
                                <Form.Group controlId="recipientId">
                                
                                    <Form.Control
                                        as="select"
                                        value={selectedUser ? selectedUser.fullName : ''}
                                        onChange={(e) => handleUserSelect(users.find(user => user.fullName === e.target.value))}
                                        isInvalid={!!recipientError}
                                        style={styles.select}
                                    >
                                        <option value="">Select recipient...</option>
                                        {users.map(user => (
                                            <option key={user._id} value={user.fullName}>
                                                {user.fullName} ({user.role})
                                            </option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {recipientError}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="subject">
                                  
                                    <Form.Control
                                        type="text"
                                        placeholder='Type your subject here...'
                                        value={subject}
                                        onChange={(e) => setSubject(e.target.value)}
                                        required
                                        style={styles.input}
                                    />
                                </Form.Group>

                                <Form.Group controlId="content">
                                    <Form.Label>Content:</Form.Label>
                                    <div style={styles.quillWrapper}>
                                        <ReactQuill
                                            value={content}
                                            onChange={setContent}
                                            style={styles.quill}
                                        />
                                      
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        {contentError}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {error && <Alert variant="danger">{error}</Alert>}

                                <Button
  variant="contained"
    type="submit"
    disabled={loading}
    style={{
        backgroundColor: '#0468B1', // Primary color
        borderColor: '#0468B1',     // Border color
        color: 'white'               // Text color
    }}
>
    {loading ? (
        <>
            <Spinner animation="border" size="sm" /> Sending...
        </>
    ) : (
        'Send Message'
    )}
</Button>

                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

const styles = {
    composeContainer: {
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        padding: '30px',
        margin: '20px auto',
        maxWidth: '900px', // Adjusted width for a wider form
    },
    header: {
        marginBottom: '20px',
        fontSize: '28px', // Enlarged header font size
        fontWeight: '600',
        color: '#333',
    },
 
    select: {
        borderRadius: '4px',
        borderColor: '#ddd',
        marginBottom: '15px',
    },
    input: {
        borderRadius: '4px',
        borderColor: '#ddd',
        marginBottom: '15px',
    },
    quillWrapper: {
        position: 'relative',
    },
    quill: {
        borderRadius: '4px',
        borderColor: '#ddd',
        marginBottom: '15px',
      
    },
    placeholder: {
        position: 'absolute',
        top: '50px',
        left: '15px',
        color: '#aaa',
        pointerEvents: 'none',
        fontSize: '16px',
        zIndex: 1,
        lineHeight: '1.5em', // Adjust for vertical alignment
        opacity: 0.5, // Slightly transparent
    },
    button: {
        backgroundColor: '#0099D8', // UNDP color
        borderColor: '#0099D8', // Match border color to the background
        color: '#fff', // White text color
        borderRadius: '4px',
        marginTop: '20px',
  
        fontSize: '16px', // Increase font size for better readability
        fontWeight: 'bold', // Make font weight bold for prominence
        padding: '10px', // Increase padding for a more substantial button
    },
};

export default SendMessage;
