import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { signout } from "../../actions";
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  Badge,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
  useMediaQuery,
  useTheme,
  SwipeableDrawer,
  Tooltip,
  Box,
  Divider
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import FavoriteIcon from '@mui/icons-material/Favorite';
import axios from "../../helpers/axios";
import GroupIcon from '@mui/icons-material/Group';
import HelpIcon from '@mui/icons-material/Help';


const placeholderImage = 'img/placeholder.jpg';

const Header = ({ open, handleDrawerOpen, userId }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation(); // Get the current location from React Router

  const logout = () => {
    dispatch(signout());
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [profileData, setProfileData] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(`/users/profile/${userId}`);
        const data = response.data;
        setProfileData(data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfileData();
  }, [userId]);

  const isActive = (path) => location.pathname === path;

  const drawerContent = (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box sx={{ textAlign: 'center', padding: '16px 0' }}>
      <img src="/static/undplogo.png" alt="Logo" style={{ width: '80px', height: 'auto' }} />
    </Box>
    <Divider />
      <List>
        <Tooltip title="Home" arrow>
          <Box  sx={{
                  '&:hover': { color: 'white' },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
            <ListItem
              button
              component={Link}
              to="/main"
              sx={{
                borderRadius: '50%',
                width: 60,
                height: 60,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: isActive('/main') ? '#0468B1' : 'transparent',
                color: isActive('/main') ? 'white' : 'inherit',
                padding: 0
              }}
            >
                               <ListItemIcon sx={{ color: 'inherit', minWidth: 0 }}>
                <HomeIcon />
              </ListItemIcon>
            </ListItem>
          </Box>
        </Tooltip>

        <Tooltip title="My Innovations" arrow>
        <Box  sx={{
                  '&:hover': { color: 'white' },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
            <ListItem
              button
              component={Link}
              to="/innovations"
              sx={{
                borderRadius: '50%',
                width: 60,
                height: 60,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: isActive('/innovations') ? '#0468B1' : 'transparent',
                color: isActive('/innovations') ? 'white' : 'inherit',
                padding: 0
              }}
            >
                               <ListItemIcon sx={{ color: 'inherit', minWidth: 0 }}>
                <img src="/static/bulb.png" alt="Innovations" style={{ width: 40, height: 40 }} />
              </ListItemIcon>
            </ListItem>
          </Box>
        </Tooltip>

        <Tooltip title="Investors" arrow>
        <Box  sx={{
                  '&:hover': { color: 'white' },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
            <ListItem
              button
              component={Link}
              to="/investors"
              sx={{
                borderRadius: '50%',
                width: 60,
                height: 60,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: isActive('/investors') ? '#0468B1' : 'transparent',
                color: isActive('/investors') ? 'white' : 'inherit',
                padding:0
              }}
            >
                                <ListItemIcon sx={{ color: 'inherit', minWidth: 0 }}>
                <img src="/static/investors.png" alt="Investors" style={{ width: 35, height: 35 }} />
              </ListItemIcon>
            </ListItem>
          </Box>
        </Tooltip>
      </List>
    </div>
  );

  return (
    <AppBar position="fixed" elevation={1} color="inherit" sx={{ backgroundColor: 'white', height: 100, padding: '9px 16px' }}>
      <Toolbar sx={{ justifyContent: isMobile ? 'space-between' : 'flex-end', minHeight: '48px' }}>
        {isMobile ? (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <div style={{ marginRight: 'auto' }}>
            <Typography className="mr-4 ml-2 cursor-pointer py-1.5 font-bold">
              <img src="/static/undplogo.png" alt="Logo" style={{ width: '100px', height: 'auto' }} />
            </Typography>
          </div>
        )}

        {isMobile ? (
          <SwipeableDrawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            {drawerContent}
          </SwipeableDrawer>
        ) : (
          <List sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
            <Tooltip title="Home" arrow>
            <Box  sx={{
                  '&:hover': { color: 'white' },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <ListItem
                  button
                  component={Link}
                  to="/main"
                  sx={{
                    borderRadius: '50%',
                    width: 60,
                    height: 60,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: isActive('/main') ? '#0468B1' : 'transparent',
                    color: isActive('/main') ? 'white' : 'inherit',
                    padding: 0
                  }}
                >
                  <ListItemIcon sx={{ color: 'inherit', minWidth: 0 }}>
                    <HomeIcon />
                  </ListItemIcon>
                </ListItem>
              </Box>
            </Tooltip>

            <Tooltip title="My Innovations" arrow>
            <Box  sx={{
                  '&:hover': { color: 'white' },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <ListItem
                  button
                  component={Link}
                  to="/innovations"
                  sx={{
                    borderRadius: '50%',
                    width: 60,
                    height: 60,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: isActive('/innovations') ? '#0468B1' : 'transparent',
                    color: isActive('/innovations') ? 'white' : 'inherit',
                    padding: 0
                  }}
                >
                  <ListItemIcon sx={{ color: 'inherit', minWidth: 0 }}>
                    <img src="/static/bulb.png" alt="Innovations" style={{ width: 40, height: 40 }} />
                  </ListItemIcon>
                </ListItem>
              </Box>
            </Tooltip>

            <Tooltip title="Investors" arrow>
            <Box  sx={{
                  '&:hover': { color: 'white' },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <ListItem
                  button
                  component={Link}
                  to="/investors"
                  sx={{
                    borderRadius: '50%',
                    width: 60,
                    height: 60,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: isActive('/investors') ? '#0468B1' : 'transparent',
                    color: isActive('/investors') ? 'white' : 'inherit',
                    padding: 0
                  }}
                >
                  <ListItemIcon sx={{ color: 'inherit', minWidth: 0 }}>
                    <img src="/static/investors.png" alt="Investors" style={{ width: 35, height: 35 }} />
                  </ListItemIcon>
                </ListItem>
              </Box>
            </Tooltip>


        
          </List>
        )}

        <div>
          <Tooltip title={profileData ? profileData.username : "Profile"} arrow>
            <IconButton onClick={handleClick}>
              <Avatar
                alt={profileData ? profileData.username : 'Profile'}
                src={profileData ? profileData.profilePicture : placeholderImage}
              />
            </IconButton>
          </Tooltip>
          
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleUserMenuClose}
          >
           

           <MenuItem component={Link} to="/myprofile" onClick={handleUserMenuClose}>
  <ListItemIcon sx={{ minWidth: 40 }}> {/* Adjust minWidth if needed */}
    <Avatar
      alt="User"
      src={profileData ? profileData.profilePicture : placeholderImage}
      sx={{ marginRight: 1 }} // Add space between the avatar and the text
    />
  </ListItemIcon>
  <ListItemText 
    primary={
      <Typography style={{ fontWeight: 300 }}>
        {profileData ? profileData.fullName : "Profile"}
      </Typography>
    } 
  />
</MenuItem>

            
            <MenuItem component={Link} to="/message" onClick={handleUserMenuClose}>
  <ListItemIcon>
    <Badge badgeContent={profileData?.inboxCount} color="primary">
      <EmailIcon fontSize="small" />
    </Badge>
  </ListItemIcon>
  <ListItemText
    primary={
      <Typography style={{ fontWeight: 300 }}>
        Inbox
      </Typography>
    }
  />
</MenuItem>
            <MenuItem component={Link} to="/favourites" onClick={handleUserMenuClose}>
              <ListItemIcon>
                <FavoriteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
    primary={
      <Typography style={{ fontWeight: 300 }}>
       Favourites
      </Typography>
    }
  />
            </MenuItem>
            <MenuItem component={Link} to="/help" onClick={handleUserMenuClose}>
  <ListItemIcon sx={{ minWidth: 40 }}>
    <HelpIcon fontSize="small" />
  </ListItemIcon>
  <ListItemText 
    primary={
      <Typography style={{ fontWeight: 300 }}>
        Help
      </Typography>
    } 
  />
</MenuItem>
            <Divider/>
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
