import React, { useState } from 'react';
import { CssBaseline } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Dashboard from './dashboard';
import Layout from './layout'; // Assuming you have a Layout component

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

function Home() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
    
      <Layout>
        <Dashboard />
      </Layout>
    </div>
  );
}

export default Home;
