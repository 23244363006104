import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from './layout';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
  TextField,
  Link,
  Avatar,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const placeholderImage = 'static/placeholder.png'; // Ensure this path is correct

const InvestorsPage = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://api.ghsdginnovations.org/api/users/getAll');
        setUsers(response.data);
        setFilteredUsers(response.data.filter(user => user.role === 'investor'));
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const filtered = users.filter(user =>
      user.fullName.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredUsers(filtered.filter(user => user.role === 'investor'));
  }, [filter, users]);

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  return (
    <Layout>
      <Box marginTop='150px' paddingBottom='60px'>
      <Typography 
  variant="h6" 
  gutterBottom 
  style={{
    fontFamily: 'Noto Sans, sans-serif',
    fontWeight: 'bold',
    color: '#0468B1', // A nice shade of blue
    letterSpacing: '1px',
    textTransform: 'uppercase',
  
  }}
> 
          List of Investors
        </Typography>
      
        <TextField
          id="filter"
          label="Filter by name"
          value={filter}
          onChange={handleFilterChange}
          fullWidth
          margin="normal"
          variant="outlined"
          style={{ width: '300px', marginBottom:'30px' }}
        />
        <Grid container spacing={3}>
          {filteredUsers.map((user) => (
            <Grid item xs={12} sm={6} md={4} key={user._id}>
            <Card>
              <Box display="flex" justifyContent="center" mt={2}>
                <Box
                  component="img"
                  src={user.profilePicture || placeholderImage}
                  alt={user.fullName}
                  sx={{
                    width: 100,
                    height: 100,
                    borderRadius: '50%',
                    objectFit: 'cover',
                    border: '2px solid #ddd',
                  }}
                />
              </Box>
              <CardContent>
                <Typography variant="h6" align="center">
                  {user.fullName}
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center">
                  Thematic Area: 
                </Typography>
               
                <Box marginTop={2} display="flex" justifyContent="center">
                  <Link
                    component={RouterLink}
                    to={`/investor/${user._id}`}
                    underline="none"
                  >
                    View Details
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          ))}
        </Grid>
      </Box>
    </Layout>
  );
};

export default InvestorsPage;
