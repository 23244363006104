import { Grid, Card, CardContent, Button, CardActions, Typography, CardMedia, Dialog, DialogTitle, DialogContent, DialogActions, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Layout from './layout';
import { Link } from 'react-router-dom'; 
import useUserInnovation from '../../hooks/userInnovations';
import { useState } from 'react';
import AddInnovation from '../../features/innovations/AddInnovation';
import { deleteInnovationById } from '../../actions';

const Dashboard = ({ innovations }) => {

  const [listOfInnovations] = useUserInnovation();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDelete = (id) => {
    deleteInnovationById(id);
  };
  
  const approvedInnovations = listOfInnovations.filter((innovation) => innovation.approved);

  return (
    <Layout>
      <Box marginTop='100px' marginBottom='150px' paddingBottom='100px' overflowY='scroll'>
        
      <Typography variant="h6" gutterBottom>
        Approved Innovations
        </Typography>
    
      
        {approvedInnovations.length === 0 ? (
          <Typography variant="h6" style={{ color: '#0468B1', display: 'flex', alignItems: 'center' }}>
            <img src="static/sorry.webp" alt="No innovations" style={{ marginRight: '10px', width: '80px', height: '80px' }} />
            No approved innovations yet. 
          </Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Innovation Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Status</TableCell>
                 
                </TableRow>
              </TableHead>
              <TableBody>
                {approvedInnovations.map((innovation) => (
                  <TableRow key={innovation._id}>
                    <TableCell>
                      <Link to={`/innovation/${innovation._id}`}>
                      <img src={innovation.featuredImg || 'static/placeholder.png'} alt="Innovation" style={{ width: '100px', height: '60px' }} />

                      </Link>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: '#0468B1' }}>
                        <Link to={`/innovation/${innovation._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                          {innovation.innovationName}
                        </Link>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" color="textSecondary" gutterBottom>
                        {innovation.description}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" color="textSecondary" gutterBottom>
                        <span style={{ color: innovation.approved ? 'green' : 'orange' }}>
                          {innovation.approved ? 'Approved' : 'Pending'}
                        </span>
                      </Typography>
                    </TableCell>
                    {/* <TableCell>
                      {innovation.approved && (
                        <CardActions>
                          <Button variant="outlined" color="primary">
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            color="warning"
                            onClick={() => handleDelete(innovation._id)}
                          >
                            Delete
                          </Button>
                        </CardActions>
                      )}
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        </Box>
    
     
    </Layout>
  );
};

export default Dashboard;
