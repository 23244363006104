import React, { useState } from 'react';
import SendMessage from './sendMessage';
import ReceivedMessages from './receivedMessages';
import Layout from '../home/layout';
import { Tab, Tabs } from 'react-bootstrap';
import SentMessages from './sent';

const Message = () => {
    const [activeTab, setActiveTab] = useState('inbox');

    const handleTabChange = (tab) => {
      setActiveTab(tab);
    };

    const tabStyle = (tab) => ({
      color: activeTab === tab ? '#000' : '#0468B1',  // Active tab color
      fontWeight: activeTab === tab ? 'bold' : 'normal'
    });

    return (
        <div style={{ marginTop: '150px', marginBottom: '60px', textAlign: 'center' }}>
            <Layout>
                <div className="message-container">
                    <div className="message-header">
                        <Tabs
                            id="message-tabs"
                            activeKey={activeTab}
                            onSelect={(tab) => handleTabChange(tab)}
                            className="mb-3"
                        >
                            <Tab eventKey="inbox" title={<span style={tabStyle('inbox')}>Inbox</span>}>
                                <ReceivedMessages />
                            </Tab>
                            <Tab eventKey="sent" title={<span style={tabStyle('sent')}>Compose</span>}>
                                <SendMessage />
                            </Tab>
                            <Tab eventKey="outbox" title={<span style={tabStyle('outbox')}>Sent</span>}>
                                <SentMessages/>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default Message;
