import React, { useState, useEffect } from 'react';
import Layout from './layout';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import axios from '../../helpers/axios'; // Ensure you have axios configured properly

const NotificationsAndMessaging = ({ loggedInUserEmail }) => {
  const [approvalMessages, setApprovalMessages] = useState([]);
  const [rejectionMessages, setRejectionMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMessages = async () => {
      setLoading(true);
      try {
        const [approvalResponse, rejectionResponse] = await Promise.all([
          axios.get(`/users/approval-messages/${loggedInUserEmail}`),
          axios.get(`/users/rejection-messages/${loggedInUserEmail}`)
        ]);
        
        if (approvalResponse.data.success) {
          setApprovalMessages(approvalResponse.data.data);
        }
        if (rejectionResponse.data.success) {
          setRejectionMessages(rejectionResponse.data.data);
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, [loggedInUserEmail]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Layout>
      <div style={{ marginTop: '100px', marginBottom: '100px' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <List sx={{ width: '100%', maxWidth: 600 }}>
              {approvalMessages.map((message, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    alignItems="flex-start"
                    sx={{ borderRadius: '10px', backgroundColor: '#d4edda', marginBottom: '10px' }}
                  >
                    <ListItemAvatar>
                      <Avatar alt="Avatar" src="/static/images/avatar.jpg" />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Your Innovation Has Been Approved!"
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {message.createdAt}
                          </Typography>
                          {` - ${message.message}`}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  {index < approvalMessages.length - 1 && <Divider variant="inset" component="li" />}
                </React.Fragment>
              ))}
            </List>
          </Grid>
          <Grid item xs={6}>
            <List sx={{ width: '100%', maxWidth: 600 }}>
              {rejectionMessages.map((message, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    alignItems="flex-start"
                    sx={{ borderRadius: '10px', backgroundColor: '#f8d7da', marginBottom: '10px' }}
                  >
                    <ListItemAvatar>
                      <Avatar alt="Avatar" src="/static/images/avatar.jpg" />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Your Innovation Has Been Rejected!"
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {message.createdAt}
                          </Typography>
                          {` - ${message.message}`}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  {index < rejectionMessages.length - 1 && <Divider variant="inset" component="li" />}
                </React.Fragment>
              ))}
            </List>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export default NotificationsAndMessaging;
