import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Layout from './layout';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Divider,
  Tooltip,
  IconButton,
  Pagination
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BuildIcon from '@mui/icons-material/Build';
import LightbulbIcon from '@mui/icons-material/Lightbulb';


const placeholderImage = 'static/placeholder.jpg';

const Main = () => {
  const [approvedInnovations, setApprovedInnovations] = useState([]);
  const [regionNames, setRegionNames] = useState({});
  const [sectorNames, setSectorNames] = useState({});
  const [statusNames, setStatusNames] = useState({});
  const [goalData, setGoalData] = useState({});
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedSector, setSelectedSector] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedGoal, setSelectedGoal] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  useEffect(() => {
    const fetchAllInnovations = async () => {
      try {
        const response = await axios.get('https://api.ghsdginnovations.org/api/innovations/getAll');
        const allInnovations = response.data;
        const filteredInnovations = allInnovations.filter((innovation) => innovation.approved);
        setApprovedInnovations(filteredInnovations);
        await fetchRegionNames(filteredInnovations);
        await fetchSectorNames(filteredInnovations);
        await fetchStatusNames(filteredInnovations);
        await fetchGoalData(filteredInnovations);
      } catch (error) {
        console.error('Error fetching innovations:', error);
      }
    };

    const fetchRegionNames = async (innovations) => {
      const uniqueRegionIds = [...new Set(innovations.map((innovation) => innovation.regionId))];
      const regionNamePromises = uniqueRegionIds.map(async (regionId) => {
        try {
          const response = await axios.get(`https://api.ghsdginnovations.org/api/regions/${regionId}`);
          return { regionId, name: response.data.name };
        } catch (error) {
          console.error(`Error fetching region with ID ${regionId}:`, error);
          return { regionId, name: 'Unknown' };
        }
      });
      const regionNamesArray = await Promise.all(regionNamePromises);
      const regionNamesMap = regionNamesArray.reduce((acc, { regionId, name }) => {
        acc[regionId] = name;
        return acc;
      }, {});
      setRegionNames(regionNamesMap);
    };

    const fetchSectorNames = async (innovations) => {
      const uniqueSectorIds = [...new Set(innovations.map((innovation) => innovation.sectorId))];
      const sectorNamePromises = uniqueSectorIds.map(async (sectorId) => {
        try {
          const response = await axios.get(`https://api.ghsdginnovations.org/api/sectors/${sectorId}`);
          return { sectorId, name: response.data.name };
        } catch (error) {
          console.error(`Error fetching sector with ID ${sectorId}:`, error);
          return { sectorId, name: 'Unknown' };
        }
      });
      const sectorNamesArray = await Promise.all(sectorNamePromises);
      const sectorNamesMap = sectorNamesArray.reduce((acc, { sectorId, name }) => {
        acc[sectorId] = name;
        return acc;
      }, {});
      setSectorNames(sectorNamesMap);
    };

    const fetchStatusNames = async (innovations) => {
      const uniqueStatusIds = [...new Set(innovations.map((innovation) => innovation.innovationStatusId))];
      const statusNamePromises = uniqueStatusIds.map(async (statusId) => {
        try {
          const response = await axios.get(`https://api.ghsdginnovations.org/api/status/${statusId}`);
          return { statusId, name: response.data.name };
        } catch (error) {
          console.error(`Error fetching status with ID ${statusId}:`, error);
          return { statusId, name: 'Unknown' };
        }
      });
      const statusNamesArray = await Promise.all(statusNamePromises);
      const statusNamesMap = statusNamesArray.reduce((acc, { statusId, name }) => {
        acc[statusId] = name;
        return acc;
      }, {});
      setStatusNames(statusNamesMap);
    };

    const fetchGoalData = async (innovations) => {
      const uniqueGoalIds = [...new Set(innovations.map((innovation) => innovation.goalId).filter((id) => id))];
      const goalDataPromises = uniqueGoalIds.map(async (goalId) => {
        try {
          const response = await axios.get(`https://api.ghsdginnovations.org/api/goals/${goalId}`);
          return { goalId, name: response.data.name, icon: response.data.icon, no: response.data.no };
        } catch (error) {
          console.error(`Error fetching goal with ID ${goalId}:`, error);
          return { goalId, name: 'Unknown', icon: '', no: '' };
        }
      });
      const goalDataArray = await Promise.all(goalDataPromises);
      const goalDataMap = goalDataArray.reduce((acc, { goalId, name, icon, no }) => {
        acc[goalId] = { name, icon, no };
        return acc;
      }, {});
      setGoalData(goalDataMap);
    };
    

    fetchAllInnovations();
  }, []);

  const handleFilterChange = (filterType, value) => {
    if (filterType === 'region') setSelectedRegion(value);
    if (filterType === 'sector') setSelectedSector(value);
    if (filterType === 'status') setSelectedStatus(value);
    if (filterType === 'goal') setSelectedGoal(value);
    setCurrentPage(1);
  };

  const getStatusIcon = (statusName) => {
    switch (statusName) {
      case 'Production':
        return <CheckCircleIcon style={{ marginRight: 4 }} />;
      case 'Development':
        return <BuildIcon style={{ marginRight: 4 }} />;
      case 'Ideation':
        return <LightbulbIcon style={{ marginRight: 4 }} />;
      default:
        return null;
    }
  };


  const filteredInnovations = approvedInnovations.filter((innovation) => {
    return (
      (!selectedRegion || innovation.regionId === selectedRegion) &&
      (!selectedSector || innovation.sectorId === selectedSector) &&
      (!selectedStatus || innovation.innovationStatusId === selectedStatus) &&
      (!selectedGoal || innovation.goalId === selectedGoal)
    );
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedInnovations = filteredInnovations.slice(startIndex, startIndex + itemsPerPage);
  const pageCount = Math.ceil(filteredInnovations.length / itemsPerPage);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };


 

  const truncateDescription = (description, wordLimit) => {
    const words = description.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return description;
  };

  return (
    <Layout>
   
      <div style={{ marginTop: '150px', marginBottom: '20px' }}>
      <Typography 
  variant="h6" 
  gutterBottom 
  style={{
    fontFamily: 'Noto Sans, sans-serif',
    fontWeight: 'bold',
    color: '#0468B1', // A nice shade of blue
    letterSpacing: '1px',
    textTransform: 'uppercase',
    
  }}
>
  Innovations
</Typography>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={selectedRegion}
            onChange={(e) => handleFilterChange('region', e.target.value)}
            displayEmpty
          >
            <MenuItem value=''><em>Region</em></MenuItem>
            {Object.entries(regionNames).map(([id, name]) => (
              <MenuItem key={id} value={id}>{name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={selectedSector}
            onChange={(e) => handleFilterChange('sector', e.target.value)}
            displayEmpty
          >
            <MenuItem value=''><em>Sector</em></MenuItem>
            {Object.entries(sectorNames).map(([id, name]) => (
              <MenuItem key={id} value={id}>{name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={selectedStatus}
            onChange={(e) => handleFilterChange('status', e.target.value)}
            displayEmpty
          >
            <MenuItem value=''><em>Innovation Stage</em></MenuItem>
            {Object.entries(statusNames).map(([id, name]) => (
              <MenuItem key={id} value={id}>{name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={selectedGoal}
            onChange={(e) => handleFilterChange('goal', e.target.value)}
            displayEmpty
          >
            <MenuItem value=''><em>SDG Goal</em></MenuItem>
            {Object.entries(goalData).map(([id, { name }]) => (
              <MenuItem key={id} value={id}>{name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ marginBottom: '100px' }}>
        {paginatedInnovations.length === 0 ? (
          <Typography variant="body1">No innovations found.</Typography>
        ) : (
          <Grid container spacing={3}>
            {paginatedInnovations.map((innovation) => (
              <Grid item xs={12} sm={6} md={4} key={innovation._id}>
                <Card
                  sx={{
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={innovation.featuredImg || placeholderImage}
                    alt={innovation.innovationName}
                    style={{
                      objectFit: 'contain', // Resize the image to fit within the container without cropping
                      width: '100%', // Full width of the container
                      height: '200px', // Fixed height for all images
                    }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <Tooltip title={goalData[innovation.goalId]?.name || 'Unknown'}>
      
  <img 
    src={goalData[innovation.goalId]?.icon || 'Unknown'} 
    alt={goalData[innovation.goalId]?.name || 'Unknown'} 
    style={{ 
      width: '26px',
   
      marginLeft: '15px',
      transition: 'transform 0.2s, box-shadow 0.2s',
      cursor: 'pointer', 
      '&:hover': {
        transform: 'scale(1.9)',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', 
      },
    }} 
  />
</Tooltip>

    <IconButton
      component={Link}
      to={`/innovation/${innovation._id}`}
      sx={{
        width: '26px',
        bottom: 8, // Position the icon 8px from the bottom of the image
        right: 8,  // Position the icon 8px from the right side of the image
        backgroundColor: '#FFA500',
        color: '#fefefe',
      
        '&:hover': {
          color: '#fefefe',
          backgroundColor: '#0468B1',
        },
        padding: '4px', // Smaller button size
      }}
    >
      <ArrowForwardIcon sx={{ fontSize: '18px' }} />
    </IconButton>
    </Box>
  
             <CardContent sx={{ height: 210 }}>
             <Typography
  component="div"
  variant="h6"
  sx={{
    fontWeight: 'bold',
    fontSize: '1.25rem',
    color: '#333', // Darker color for the name
    marginBottom: '8px',
    overflow: 'hidden',       // Hides the overflow content
    textOverflow: 'ellipsis',  // Adds the ellipsis
    whiteSpace: 'nowrap',      // Prevents text from wrapping
    maxWidth: '100%',          // Ensures the element doesn't exceed its container
    display: 'block',          // Ensures the element behaves as a block for the ellipsis to apply
  }}
>
  {innovation.innovationName}
</Typography>



  <Typography
    variant="body2"
    color="text.secondary"
    sx={{
      marginBottom: '12px', // Equal spacing
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 3,
    }}
  >
    {truncateDescription(innovation.description, 20)}
  </Typography>

  <Typography
    variant="body2"
    sx={{
      color: '#0468B1',
      marginBottom: '12px', // Equal spacing
      display: 'flex',
      alignItems: 'center',
    }}
  >
    {getStatusIcon(statusNames[innovation.innovationStatusId])}
    {statusNames[innovation.innovationStatusId]}
  </Typography>

  <Typography
    variant="body2"
    sx={{
      color: '#FFA500',
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <LocationOnIcon style={{ marginRight: 1, color: '#FFA500' }} />
    {regionNames[innovation.regionId] || 'Unknown'}
  </Typography>
</CardContent>

                  
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
       <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Pagination
          count={pageCount}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
      </div>
    </Layout>
  );
};

export default Main;
