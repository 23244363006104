// import React from "react";
// import { Outlet, Navigate } from "react-router-dom";

// const PrivateRoute = () => {
//   return localStorage.getItem('token') != null ? <Outlet /> : <Navigate to="/login" />;
// };
// export default PrivateRoute;

import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoute = ({ allowedRoles }) => {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');  // Assuming role is also stored in local storage

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (allowedRoles && !allowedRoles.includes(role)) {
    return <Navigate to="/unauthorized" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
