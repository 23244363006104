import React, { useState, useEffect } from 'react';
import { Box, styled } from '@mui/system';
import { Card, Grid, Button, Stack, TextField, Typography } from '@mui/material';
import { Input, Form } from 'reactstrap';
import { useNavigate } from 'react-router';
import swal from 'sweetalert';
import Logo from '../../components/Logo';
import axios from '../../helpers/axios';
import { Span } from '../../components/Typography';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: 'center',
}));

styled('img')(() => ({
  width: '100%',
}));

const JWTRegister = styled(JustifyBox)(() => ({
  backgroundImage: `url('path_to_your_background_image.jpg')`, // Replace with your background image path
  backgroundSize: 'cover',
  minHeight: '100vh !important',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .card': {
    maxWidth: 500,
    borderRadius: 12,
    background: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background for card
 
    textAlign: 'center',
  },
}));

const formDataInitial = {
  fullName: '',
  email: '',
  password: '',
};
const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validatePassword = (password) => {
  const hasUppercase = /[A-Z]/.test(password);
  const hasNumber = /[0-9]/.test(password);

  const isValid = hasUppercase && hasNumber ;
  let strength = 'Weak';
  if (isValid) {
      strength = 'Strong';
  }
  return { isValid, strength };
};


const Register = () => {
  const [formData, setFormData] = useState(formDataInitial);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isEmailValid, setIsEmailValid] = useState(false);
    const [passwordStatus, setPasswordStatus] = useState({ isValid: false, strength: 'Weak' });
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);

    useEffect(() => {
      setIsEmailValid(validateEmail(formData.email));
      setPasswordStatus(validatePassword(formData.password));
  }, [formData.email, formData.password]);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }
  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
};

  const userSignup = async (e) => {
    e.preventDefault();
    if (!isEmailValid || !passwordStatus.isValid) {
        return;
    }
    setIsLoading(true);
    try {
        await axios.post(`/auth/register`, formData);
        setIsLoading(false);
        await swal('Success', 'Account sign up successful', 'success');
        navigate('/login');
    } catch (err) {
        setIsLoading(false);
        const message = err.response?.status === 422 ? err.response.data.error : 'An error occurred. Please try again!';
        await swal('Sign up Error', message, 'error');
    }
};

  return (
   <JWTRegister>
            <Card className="card text-center">
            <div className="d-flex flex-column col">
           
                          
                            <Box p={4} height="100%">
                            <div className="d-flex align-items-center justify-content-center mb-4">
    <Box component="img" src="https://digitalx.undp.org/images/630478c456d6a9a3e003e9f2_logo-blue.svg" sx={{ width: 74, height: 130 }} />
  </div>
  <h4 style={{ marginTop: '20px' }}>Sign Up</h4>
                            <Form onSubmit={userSignup} style={{ marginTop: '20px' }}>
                                <Stack spacing={4}>
                                    <TextField
                                        variant="outlined"
                                        label="Full Name"
                                        name="fullName"
                                        value={formData.fullName}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                      
                                    />
                                    <TextField
                                        variant="outlined"
                                        label="Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        style={{ marginTop: '25px' }}
                                        error={!isEmailValid && formData.email.length > 0}
                                        helperText={!isEmailValid && formData.email.length > 0 ? 'Invalid email address' : ''}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label="Password"
                                        name="password"
                                        type="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        onFocus={handlePasswordFocus}
                                        fullWidth
                                        required
                                        style={{ marginTop: '25px' }}
                                        error={!passwordStatus.isValid && formData.password.length > 0}
                                        helperText={!passwordStatus.isValid && formData.password.length > 0 ? 'Password must contain an uppercase letter, and a number' : ''}
                                    />
                                    {isPasswordFocused && (
                                        <Typography color={passwordStatus.isValid ? 'green' : 'red'}>
                                            Password strength: {passwordStatus.strength}
                                        </Typography>
                                    )}
                                    
                                </Stack>
                             
                                <div className="form-group"   style={{ marginTop: '20px' }}>
                                    <Button
                                        type="submit"
                                        disabled={isLoading }
                                        sx={{ textTransform: 'capitalize' }}
                                    >
                                        {isLoading ? 'Please wait...' : 'Sign up'}
                                    </Button>
                                    <Span sx={{ mr: 1, ml: '20px' }}>or</Span>
                                    <Button
                                        sx={{ textTransform: 'capitalize' }}
                                        onClick={() => navigate("/login/")}
                                    >
                                        Sign in
                                    </Button>
                                    </div>
                            </Form>
                        </Box>
                    
          </div>
            </Card>
        </JWTRegister>
  );
};

export default Register;
